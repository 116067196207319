import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sepa-dotnet',
  templateUrl: './sepa-dotnet.component.html',
  styleUrls: ['./sepa-dotnet.component.css']
})
export class SepaDotnetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
