import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { SepaDotnetComponent } from './sepa-dotnet/sepa-dotnet.component';
import { DynamicPostersComponent } from './dynamic-posters/dynamic-posters.component';
import { PrijzenComponent } from './prijzen/prijzen.component';
import { NieuwsteOntwikkelingenComponent } from './nieuwste-ontwikkelingen/nieuwste-ontwikkelingen.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'sepa-dotnet', component: SepaDotnetComponent },
  { path: 'dynamic-posters', component: DynamicPostersComponent },
  { path: 'prijzen', component: PrijzenComponent },
  { path: 'nieuwste-ontwikkelingen', component: NieuwsteOntwikkelingenComponent },
  { path: 'contact', component: ContactComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    // uncomment this for "hash-bang" routing
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
})
export class AppRoutingModule { }
