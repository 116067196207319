import { Component } from '@angular/core';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TurboFrontoffice';
  activePage: string;

  constructor(private router: Router) {
    router.events.subscribe(ev => this.setActivePage(ev));
  };

  setActivePage(event: any): void {
    if (event instanceof NavigationEnd) {
      let navigationEnd: NavigationEnd = event as NavigationEnd;
      this.activePage = navigationEnd.url.substr(1);
    }
  }
}
