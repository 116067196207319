import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SepaDotnetComponent } from './sepa-dotnet/sepa-dotnet.component';
import { NieuwsteOntwikkelingenComponent } from './nieuwste-ontwikkelingen/nieuwste-ontwikkelingen.component';
import { DynamicPostersComponent } from './dynamic-posters/dynamic-posters.component';
import { ContactComponent } from './contact/contact.component';
import { PrijzenComponent } from './prijzen/prijzen.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SepaDotnetComponent,
    NieuwsteOntwikkelingenComponent,
    DynamicPostersComponent,
    ContactComponent,
    PrijzenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
